
import { useStore } from "@/store/index";
import { getAppList } from "@/api/request-modules/common";
import { updateAppList } from "@/api/request-modules/iteration";
import { ResponseParams } from "@/types/response";
import useMessageTip from "@/composables/useMessageTip";
import { defineComponent, onBeforeUpdate, PropType, watch, computed } from "vue";
import { ref } from "vue";
import { RequestParams } from "@/types/request";
import { CirclePlus, Remove } from "@element-plus/icons";

export default defineComponent({
  name: "projectManagement",
  props: {
    list: {
      default: () => [],
      type: Array as PropType<Array<any>>
    }
  },
  components: { CirclePlus, Remove },
  emits: ["refresh"],
  setup(props, { emit }) {
    const rules = {
      id: { required: true, message: "请选择应用", trigger: "blur" },
      branch: { required: true, message: "请输入分支名称", trigger: "blur" }
    };
    const store = useStore();
    const { tipMessage } = useMessageTip();
    const currentIter = computed(() => store.getters.currentIter);
    // 应用列表
    const selectLists = ref([]);
    const formRefs = ref<Array<HTMLElement & { validate: (...args: Array<any>) => void }>>([]);
    let appListform = ref([
      {
        id: "",
        branch: ""
      }
    ]);
    const dialogVisible = ref(false);
    watch(
      () => dialogVisible.value,
      (newValue) => {
        if (newValue) {
          appListform.value = [
            {
              id: "",
              branch: ""
            }
          ];
        }
      }
    );

    const handleAddItemApp = () => {
      appListform.value.push({ id: "", branch: "" });
    };
    const setRefs = (ele: HTMLElement & { validate: (...args: Array<any>) => void }) => {
      formRefs.value.push(ele as any);
    };
    onBeforeUpdate(() => {
      formRefs.value = [];
    });
    const handleRemoveItemApp = (index: number) => {
      appListform.value.splice(index, 1);
    };
    // 弹窗点击确定
    const handleCreateApp = () => {
      const flag = [] as Array<boolean>;
      for (let i = 0; i < formRefs.value.length; i++) {
        formRefs.value[i] &&
          formRefs.value[i].validate((valid: boolean) => {
            flag.push(valid);
            if (i === formRefs.value.length - 1) {
              if (flag.includes(false)) {
                return false;
              } else {
                //  提交表单
                const params = {
                  iteration_id: currentIter.value?.id,
                  list: appListform.value.concat(props.list || [])
                };
                // 新增
                // 1.获取原来的数据和现在的拼接起来
                updateAppListFn(params, (response) => {
                  if ((response as ResponseParams.ResponseDataSuccess).code === 200) {
                    tipMessage((response as ResponseParams.ResponseDataSuccess).code);
                  }
                  emit("refresh", currentIter.value?.id);
                  dialogVisible.value = false;
                });
              }
            }
          });
      }
    };

    const handleShowDialog = () => {
      getAppList<ResponseParams.ResponseDataSuccess>().then((res) => {
        selectLists.value = res.data;
      });
      dialogVisible.value = true;
    };
    const updateAppListFn = (params: RequestParams.UpdateAppList, cb?: (...args: Array<unknown>) => void) => {
      updateAppList<ResponseParams.ResponseDataSuccess>(params).then((res) => {
        cb && cb(res);
      });
    };
    //#region 删除逻辑
    const handleChangeLists = (deleteId: string) => {
      // 1.获取原来的数据列表
      const copyList = props.list.slice();
      const deleteIndex = props.list.findIndex((item) => item.id === deleteId);
      // 2.删除掉
      copyList.splice(deleteIndex, 1);
      // 3.用剩下的数据请求更新接口
      const params = {
        iteration_id: currentIter.value?.id,
        list: copyList
      };
      updateAppListFn(params, (response) => {
        if ((response as ResponseParams.ResponseDataSuccess).code === 200) {
          tipMessage((response as ResponseParams.ResponseDataSuccess).code);
        }
        emit("refresh", currentIter.value?.id);
      });
    };
    //#endregion

    return {
      setRefs,
      handleRemoveItemApp,
      handleAddItemApp,
      handleCreateApp,
      appListform,
      handleShowDialog,
      rules,
      // appList
      selectLists,
      // filterMethod,
      handleChangeLists,
      dialogVisible
    };
  }
});
