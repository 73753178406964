import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52aaac1b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_warning = _resolveComponent("warning")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_popover, {
      placement: "bottom-start",
      width: 400,
      trigger: "hover"
    }, {
      reference: _withCtx(() => [
        _createVNode(_component_el_icon, { class: "el-icon-warning-outline" }, {
          default: _withCtx(() => [
            _createVNode(_component_warning)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", {
          innerHTML: _ctx.msg[_ctx.type - 1].detail
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    })
  ]))
}