import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17946761"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-item" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "progress-item__progressText" }
const _hoisted_4 = { class: "symbol" }
const _hoisted_5 = {
  key: 0,
  class: "el-icon-top progress-item__progressText--up"
}
const _hoisted_6 = {
  key: 1,
  class: "el-icon-bottom progress-item__progressText--down"
}
const _hoisted_7 = {
  key: 1,
  class: "progress-title"
}
const _hoisted_8 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
    (!_ctx.isStatus)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.total), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.unit), 1)
          ]),
          (_ctx.today > 0)
            ? (_openBlock(), _createElementBlock("i", _hoisted_5, "↑"))
            : (_openBlock(), _createElementBlock("i", _hoisted_6, "↓")),
          _createElementVNode("span", {
            class: _normalizeClass([_ctx.today > 0 ? 'progress-item__progressText--up' : 'progress-item__progressText--down'])
          }, _toDisplayString(_ctx.today) + _toDisplayString(_ctx.unit), 3)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isStatus)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isStatus)
      ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ]))
}