
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "iterationCard",
  props: {
    title: {
      type: String as PropType<string>,
      default: ""
    },
    total: {
      type: Number as PropType<number>,
      default: 0
    },
    today: {
      type: Number as PropType<number>,
      default: 0
    },
    unit: {
      type: String as PropType<string>,
      default: ""
    },
    isStatus: {
      // 是否是发布状态card
      type: Boolean as PropType<boolean>,
      default: false
    }
  }
});
