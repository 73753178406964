
import { defineComponent, ref, watch, computed, onMounted, nextTick } from "vue";
import EmployeeManagement from "./components/employeeManagement.vue";
import projectManagement from "./components/projectManagement.vue";
import WorkDrawer from "@/businessComponents/workDrawer/index.vue";
import { downloadUrl } from "@/utils";

import useUpdateDoc from "./composables/useUpdateDoc";
import useMixin, { replaceProductId } from "../useMixin";
import useMessageTip from "@/composables/useMessageTip";
import useUpdateIterationStatus from "./hooks/useUpdateIterationStatus";
import { ResponseParams } from "@/types/response";
import iterationCard from "./components/iterationCard.vue";
import dayjs from "dayjs";
import api from "@/api/dict";
import { useRoute } from "vue-router";
import IterationTip from "@/components/iteration-tip/index.vue";
import { STATUS } from "@/utils/index";
import { updateRiskWarning, getIterationDemand, updateIterationDemand, getDocList, removeDocRelative } from "@/api/request-modules/iteration";
import { store } from "@/store";
import DemandEditor from "./components/demandEdit.vue";
import DocLog from "./components/docLog.vue";
import DocTree from "./components/docTree.vue";
import { InterDocList } from "@/composables/useIteration";
import { ElMessage } from "element-plus";
import { MutationType } from "@/store/mutation-types";
import HandleTestBill from "@/views/product/testBill/components/handleTestBill.vue";
import AffiliateProgram from "./components/affiliateProgram.vue";
import { Share, Download, MoreFilled, Edit } from "@element-plus/icons";
import copyField from "@/utils/copyField";
import { ElMessageBox } from "element-plus";
import useHome from "@/composables/useHomePage";
import { Iterate } from "@/composables/useBoardBase";
import { ProjectItem } from "@/composables/useCommon";
import router from "@/router";

export default defineComponent({
  components: {
    EmployeeManagement,
    projectManagement,
    iterationCard,
    IterationTip,
    DemandEditor,
    DocLog,
    DocTree,
    HandleTestBill,
    AffiliateProgram,
    WorkDrawer,
    Share,
    Download,
    MoreFilled,
    Edit
  },
  setup() {
    const route = useRoute();
    const visible2 = ref(false);
    const demandType = ref(1);
    const testBillComp = ref();
    const currentIter = computed(() => store?.getters?.currentIter);
    const iterateId = computed(() => store?.getters?.iterateId);
    const productId = computed(() => store?.getters?.productId);
    const iterateContentVisible = computed(() => store.state.iterateContentVisible);
    const iterateList = computed(() => store.getters.iterateList);
    const productList = computed(() => store.getters.productList);
    replaceProductId();
    //#region  资料地址模块
    const { tipMessage } = useMessageTip();
    // 资料地址相关数据
    const { handleConfirmChange, handleEditDoc, dialogDocInfo, docLists, handleDownloadDoc } = useUpdateDoc(tipMessage);
    //#endregion
    // 关联需求弹框
    const dialogRelative = ref(false);
    const programVisible = ref(false);
    // 需求原型及设计，更多信息弹框
    const visibleLog = ref(false);

    // 需求说明弹框
    const dialogVisibleDemand = ref(false);

    const visibleTree = ref(false);
    const demandList = ref([]);
    // 管理需求弹窗默认选中
    const defaultCheck = ref([] as Array<number>);
    let treeData = ref([]);
    let alreadyClick = false;

    const curProductInfo = computed(() => store?.getters?.productInfo);
    const docUiDetail: Record<string, any> = ref({});
    const { useDeleteIterationById } = useHome();
    //#region  文档
    const { handleChangeDialogStatus, dialogRef, result, isOk, getIterationDetail, progressData } = useMixin(true);
    const iterationDocList = ref<InterDocList[]>([]);
    const getDocData = () => {
      if (!iterateId.value) return;
      getDocList({
        iteration_id: iterateId.value
      }).then((res: any) => {
        if (res.code === 200) {
          iterationDocList.value = res.data;
        } else {
          iterationDocList.value = [];
        }
      });
    };

    onMounted(() => {
      getIterationDetail(currentIter.value?.id);
      getDocData();
    });

    watch(
      () => result.value,
      () => {
        if (Object.keys(result.value).length) {
          try {
            const fileds = Object.keys(result.value?.doc);
            demandList.value = result.value.doc;
            (docLists as Array<Record<string, any>>).forEach((v) => {
              v.quote_id = result.value.id;
              fileds.forEach((filed) => {
                v.doc = [result.value.doc[filed]];
              });
              riskWarningText.value = result.value.warning;
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          demandList.value = [];
        }
      }
    );
    // 迭代抽屉编辑完回调事件
    const updateDemand = () => {
      getIterationDetail(currentIter.value?.id);
    };
    watch([() => iterateContentVisible.value, () => currentIter.value], ([newVisible, newIter]) => {
      if (newVisible) {
        handleRelativeDemand(result.value.id);
      }
      if (newIter) {
        getIterationDetail(currentIter.value?.id);
        getDocData();
      } else {
        result.value = {};
      }
    });

    const { changeUpdateIterationStatus, updateStatusDialog, handleConfirmStatusChange, status, REAL_STATUS, checkInterStatus } = useUpdateIterationStatus(
      tipMessage,
      result,
      getIterationDetail
    );

    //#region 风险备注更新
    const riskModal = ref(false);
    const riskWarningText = ref("");
    // 开始联调前判断是否添加时间
    if (route.params.code) {
      setTimeout(() => {
        handleChangeDialogStatus(1);
      }, 100);
    }
    // 风险备注
    const handleChangeRiskRemark = (e: any) => {
      let target = e.target;
      if (target.nodeName == "SPAN" || target.nodeName == "I") {
        target = e.target.parentNode;
      }
      target.blur();
      riskModal.value = true;
    };
    // 项目周报
    const handleProductWeekly = async () => {
      const res = await ElMessageBox.confirm("请确认是否删除当前迭代？", "删除迭代", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "module-manage-remove-dialog"
      });

      if (res === "confirm") {
        const isSucc = await useDeleteIterationById(iterateId.value);
        if (isSucc) {
          /**
           * 1. 判断当前迭代是否还有？有的话取下一个迭代
           * 2. 如果当前没有迭代了，就直接去下一个项目，的下一个迭代
           */
          if (iterateList.value.length > 1) {
            console.log(iterateList.value, "iterateList");

            const list = iterateList.value.filter((item: Iterate) => item.id !== iterateId.value);
            if (list.length) {
              store.commit(MutationType.updateIterateList, list);
              store.commit(MutationType.updateIterateId, list[0].id);
              store.commit(MutationType.updateCurrentIter, list[0]);

              getIterationDetail(list[0].id);
              getDocData();
              router.replace({
                name: "homepage",
                query: {
                  ...route.query,
                  iterateId: list[0].id
                }
              });
            }
          } else {
            // 切换项目
            if (productList.value.length > 1) {
              const list = productList.value.filter((item: ProjectItem) => item.id !== productId.value);
              if (list.length) {
                store.commit(MutationType.updateProductList, list);
                store.commit(MutationType.updateProductId, list[0].id);
                await store.dispatch("GETITERATELIST", list[0].id);

                setTimeout(() => {
                  router.replace({
                    name: "homepage",
                    query: {
                      ...route.query,
                      iterateId: iterateId.value || undefined
                    }
                  });
                  getIterationDetail(iterateId.value || undefined);
                  getDocData();
                }, 200);
              }
            } else {
              // 没有多余项目，直接刷新当前页面
              window.location.reload();
            }
          }
        }
      }
    };

    const handleUpdateRiskWarning = () => {
      updateRiskWarning<ResponseParams.ResponseDataSuccess>({
        iteration_id: currentIter.value?.id,
        remark: riskWarningText.value
      }).then((res) => {
        if (res.code === 200) {
          tipMessage(res.code);
        }
        getIterationDetail(currentIter.value?.id);
        riskModal.value = false;
      });
    };

    const docTypeFn = (type: number) => {
      if (type) return 2;
      return 1;
    };

    // 提测单弹窗
    const onIterateFun = () => {
      if (result.value.stage > 0 && !result.value?.test_apply_id) {
        testBillComp.value.handleDrawerVisble(true, true);
      } else if (result.value?.test_apply_id) {
        testBillComp.value.showEditDrawer(result.value.test_apply_id);
      } else {
        ElMessage.warning("该阶段无法创建提测单");
      }
    };

    //#endregion
    //#region 按钮组
    const btn_groups = [
      {
        name: "编辑迭代",
        event: (e: any) => {
          return handleChangeDialogStatus(1, e);
        },
        type: "primary",
        icon: "icon-bianji",
        class: "plain"
      },
      {
        name: "修改状态",
        event: changeUpdateIterationStatus,
        type: "success",
        icon: "icon-xiugaizhuangtai"
      },
      {
        name: "迭代工单",
        event: onIterateFun,
        type: "info",
        icon: "icon-caidan",
        class: "iterationTicket"
      },
      {
        name: "风险备注",
        event: handleChangeRiskRemark,
        type: "warning",
        icon: "icon-fengxian"
      },
      {
        name: "删除迭代",
        event: handleProductWeekly,
        type: "danger",
        icon: "icon-shanchu1"
      }
    ];

    // 迭代状态下的显示内容
    const getStageDate = (iterationDetail: Record<string, any>) => {
      if (!Object.keys(iterationDetail).length) return;
      const stage = iterationDetail.stage;
      const formatDate = (date: string) => {
        return dayjs(date).format("MM.DD");
      };
      const stageMap = {
        1: "dev_time",
        2: "union_time", // 联调中显示当前联调进度
        3: "test_time", // 测试中显示当前测试进度
        4: "release_time"
      } as Record<string, any>;
      if (!iterationDetail.is_schedule) {
        let text = "";
        switch (iterationDetail.status) {
          case 6:
            return "已完成";
          case 1:
            text = "设计";
            break;
          case 2:
            text = "开发";
            break;
          case 3:
            text = "联调";
            break;
          case 4:
            text = "测试";
            break;
          default:
            return "";
        }
        return `当前${text}进度 <span style="color:rgb(0, 51, 0);font-weight: 700;"> ${iterationDetail.percent}%</span>`;
      }
      const strPlan = `计划开始日期${formatDate(iterationDetail[stageMap[stage]])}`;
      let text = "";
      switch (iterationDetail.status) {
        case 0:
          return `计划开始日期${formatDate(iterationDetail.dev_time)}`;
        case 1:
          text = "设计";
          break;
        case 2:
          text = "开发";
          break;
        case 3:
          text = "联调";
          break;
        case 4:
          text = "测试";
          break;
        case 5:
          return `计划发布日期${formatDate(iterationDetail[stageMap[stage + 1]])}`;
        case 6:
          return "已完成";
        case 7:
          return "";
        case 8:
          return `计划测试日期${formatDate(iterationDetail[stageMap[stage + 1]])}`;
        default:
          return strPlan;
      }
      return `当前${text}进度 <span style="color:rgb(0, 51, 0);font-weight: 700;"> ${iterationDetail.percent}%</span>`;
    };
    //#endregion
    //#region

    // 返回icon样式
    const backClass = (type: number) => {
      const classIconArr = ["icon-rp", "icon-unknow", "icon-doc", "icon-html", "icon-link", "icon-doc-ui"];
      if (classIconArr[type - 1]) return `iconfont ${classIconArr[type - 1]}`;
      return `iconfont icon-unknow`;
    };

    const handleType = (val: number) => {
      status.value = val;
      handleConfirmStatusChange();
    };

    //#endregion

    // 需求查看
    const handleDemand = () => {
      dialogVisibleDemand.value = true;
    };
    // 关联需求
    const handleRelativeDemand = (id: string | number, e?: any) => {
      if (e) {
        let target = e.target;
        if (target.nodeName == "SPAN" || target.nodeName == "I") {
          target = e.target.parentNode;
        }
        target.blur();
      }
      if (alreadyClick || !curProductInfo.value?.id) return;
      // 一个阈值
      treeData.value = [];
      defaultCheck.value = [];
      getIterationDemand<ResponseParams.ResponseDataSuccess>({
        with_iteration_id: id,
        product_id: curProductInfo.value?.id,
        filter_type: 2
      }).then((res: any) => {
        if (res.code === 200) {
          res.data.forEach((item: { id: number; iteration_id: number }) => {
            if (item.iteration_id === id) {
              defaultCheck.value.push(item.id);
            }
          });
          treeData.value = res.data.map((item: { id: number; name: string }) => {
            return { id: item.id, label: item.name };
          });
          alreadyClick = true;
          store.commit(MutationType.iterateContentVisible, true);
          setTimeout(() => {
            alreadyClick = false;
          }, 1000);
        } else {
          treeData.value = [];
          defaultCheck.value = [];
        }
      });
    };

    // 关联需求后回调
    const getRelativeDate = (val: number[]) => {
      updateIterationDemand<ResponseParams.ResponseDataSuccess>({
        iteration_id: result.value.id,
        demand_ids: val
      }).then((res: any) => {
        if (res.code === 200) {
          dialogRelative.value = false;
          store.commit(MutationType.iterateContentVisible, false);
          getIterationDetail(currentIter.value?.id);
          tipMessage(200, "成功");
        }
      });
    };
    const workDrawer = ref();
    const typeDrawer = ref(0);
    const targetId = ref(0);
    const handleDemandDetail = (demandId: number) => {
      if (targetId.value === demandId) {
        // 如果同样的id，还是要去请求数据
        targetId.value = -1;
        nextTick(() => {
          targetId.value = demandId;
        });
      } else {
        targetId.value = demandId;
      }
      workDrawer.value.handleDrawerVisble(true);
      typeDrawer.value = 5;
    };

    // 标题点击  类型  1.rp  2.未知  3.文件夹 4.html 5.url 6.ui
    const handleDocTitle = (val: InterDocList) => {
      if (val.type === 2) {
        downloadUrl(val.url, val.file_name);
      }
      if (val.type !== 2 && val.type !== 3) {
        if (val.type === 5) {
          window.open(val.url, "_blank");
          return;
        }
        if (val.type === 1 || val.type === 4) {
          return window.open(`/preview?id=${val.id}`, "_blank");
        }

        if (val.type === 6) window.open(val.preview_url, "_blank");
      }
    };
    //下载
    const handleDownload = (val: InterDocList) => {
      if (!val.url) return tipMessage(400, `文档合集下无文件类型文档，不可下载`);

      if (val.type === 1 || val.type === 2 || val.type === 4) {
        downloadUrl(val.url, val.file_name);
      }
    };
    const docId = ref(0);
    const handleShowLog = (val: InterDocList) => {
      docId.value = val.id;
      visibleLog.value = true;
    };

    // 分享
    const handleShare = (val: InterDocList) => {
      if (val.type === 2 || val.type === 5 || val.type === 6) {
        if (val.type === 2) copyField(val.url + `?attname=${val.file_name}`);
        else copyField(val.url);
      } else {
        if (val.type === 1 || val.type === 4) {
          copyField(window.location.origin + `/preview?id=${val.id}`);
        }
      }
    };

    // 解除关联
    const handleRemoveRelative = (val: InterDocList) => {
      ElMessageBox.confirm(val.type !== 3 ? "解除关联，是否确认？" : "将永久删除此文件集合，及其包含的文档，是否确认", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "el-button--danger-box",
        type: "error"
      }).then(async () => {
        removeDocRelative<ResponseParams.ResponseDataSuccess>({
          iteration_id: iterateId.value,
          doc_id: val.id
        }).then((res: any) => {
          if (res.code === 200) {
            tipMessage(200, "成功");
            getDocData();
          }
        });
      });
    };

    const showDemand = (type: 1 | 2, val?: InterDocList) => {
      visible2.value = true;
      demandType.value = type;
      if (type === 2) {
        docUiDetail.value = val;
      }
    };
    const showRelativeDoc = () => {
      visibleTree.value = true;
    };
    return {
      testBillComp,
      currentIter,
      iterateContentVisible,
      demandType,
      showDemand,
      visible2,
      STATUS,
      getIterationDetail,
      isOk,
      result,
      dialogRef,
      handleChangeDialogStatus,
      docLists,
      handleConfirmChange,
      dialogDocInfo,
      docTypeFn,
      handleEditDoc,
      btn_groups,
      progressData,
      updateStatusDialog,
      handleConfirmStatusChange,
      changeUpdateIterationStatus,
      status,
      REAL_STATUS,
      handleChangeRiskRemark,
      riskModal,
      riskWarningText,
      handleUpdateRiskWarning,
      getStageDate,
      api,
      handleDownloadDoc,
      handleDemandDetail,
      checkInterStatus,
      handleProductWeekly,
      handleDemand,
      dialogVisibleDemand,
      demandList,
      handleType,
      dialogRelative,
      handleRelativeDemand,
      getRelativeDate,
      treeData,
      defaultCheck,
      programVisible,
      backClass,
      iterationDocList,
      docUiDetail,
      handleDocTitle,
      handleDownload,
      handleShare,
      handleRemoveRelative,
      handleShowLog,
      docId,
      visibleLog,
      visibleTree,
      showRelativeDoc,
      getDocData,
      productId,
      iterateId,
      targetId,
      typeDrawer,
      workDrawer,
      updateDemand
    };
  }
});
